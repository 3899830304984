import './App.css';
import 'normalize.css';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="container App">
      <div className='comingSoon'>
        <h1>FenixPoint</h1>
        <p className='small'>Coming Soon</p>
      </div>  
    </div>
  );
}

export default App;
